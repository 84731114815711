<template>
  <div id="body" class="compliance">
    <iframe v-if="complianceType === 'NONE'" src="https://www.thelarkincompany.com/compliance-plans/" title="The Larkin Company compliance plans" class="web-content"></iframe>

    <a v-if="pageState !== 0"  title="launch" @click="pageState = 0" class="btn-larkin-outline back-btn-float"><i class="arrow-icon fa-solid fa-arrow-left-long mr-3"></i>Compliance Home</a>
    <iframe v-if="pageState === 1 || complianceType === 'USA'" src="https://thelarkincompany.com/cc24-lu8/" title="The Larkin Company compliance plans" class="web-content"></iframe>
    <iframe v-if="pageState === 2 || complianceType === 'CAN'" src="https://thelarkincompany.com/cc24-8rc/" title="The Larkin Company compliance plans" class="web-content"></iframe>

    <div v-if="complianceType === 'BOTH' && pageState === 0">
      <div id="upper" class="upper">
        <h1 class="header-text">
        <span class="header-text">
          Keeping Up to Date<br/>
        </span>
        </h1>
        <div class="text-divider"></div>


        <div class="body-text">
          <p>Our commitment to our partners is to ensure that they have a robust understanding of state and federal leave of absence laws and
            state wage income replacement benefits and to ensure that our partners understand their compliance obligations as an employer.</p>
        </div>
      </div>
      <div id="lower" class="lower">
        <div class="hero hero--us">
          <a title="launch" @click="pageState = 1">U.S. Compliance <i class="arrow-icon fa-solid fa-arrow-right-long ml-3"></i></a>
<!--             href="https://thelarkincompany.com/cc24-lu8/"-->
        </div>
        <div class="hero hero--ca">
          <a title="launch" @click="pageState = 2">Canada Compliance<i class="arrow-icon fa-solid fa-arrow-right-long ml-3"></i></a>
<!--             href="https://thelarkincompany.com/cc24-8rc/"-->
        </div>
      </div>
      <a></a>
    </div>
  </div>
</template>

<style lang="scss">
.body-content{
  height: 100%;
}
.web-content{
  height: 100%;
  width: 100%;
}
.arrow-icon{
  font-size: 22px;
  font-weight: 700;
}
.back-button-header{
  background-color: #f7f5f2;
  display: flex;
  height: 95px;
  width: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.back-btn{
  color: #0E1F22!important;
  display: flex;
  align-items: center;
  padding: 1em;
  &:hover{
    text-decoration: none;
  }
}
.back-btn-float{
  position: absolute;
  left: 225px;
  top: 90px;
  color: #0E1F22!important;
  background-color: #f7f5f2;
  display: flex;
  align-items: center;
  padding: 1em;
}
  .compliance{
    font-family: 'Cabin',Helvetica,Arial,Lucida,sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 675px) {
      .upper {
        height: 60%!important;
        font-size: 80%;
      }
      .header-text{
        margin-bottom: 8%!important;
      }
      .lower {
        flex-direction: column;
      }
      .hero {
        padding-top: 300px;
        margin-bottom: 50px;
      }
    }
    @media only screen and (min-width: 676px) {
      height: 100%;
      .hero {
        min-width: 529px;
        padding-top: 420px;
      }
    }
    .btn-compliance {
      text-align: center;
      position: relative;
      color: #0E1F22;
      border: 3px solid #0E1F22;
      border-radius: 100px;
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 700;
      background-color: #FFD600;
      box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
      padding: 13px;
      width: 140px;
      overflow: hidden;
    }
    .btn-slide-effect {
      transition: all 0.2s linear 0s;
    }
    .btn-slide-effect:before {
      content: '\e9a5';
      font-family: 'icomoon' !important;
      font-size: 15px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      width: 35px;
      transition: all 0.2s linear 0s;
    }

    .btn-slide-effect:hover {
      text-indent: -20px;
      color: #0E1F22;
      width: 160px;
    }

    .btn-slide-effect:hover:before {
      opacity: 1;
      text-indent: 0px;
    }
    .hero-icon{
      font-size: 60px;
      padding: 12px;
      border-radius: 45px;
      color: #bcbcbc;
      background-color: #0e1f22;
      animation: 1s ease-out 0s 1 dropIn;
    }
    .text-divider{
      background-color: #FFD600;
      //border-radius: 20px 20px 20px 20px;
      overflow: hidden;
      height: 5px;
      margin-top: -18px!important;
      width: 160px;
      margin-bottom: 2.75%;
      animation: 1s ease-out 0s 1 phaseIn;
    }
    .header-text{
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 2.75%;
    }
    .upper{
      //height: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .lower{
      //width: 90%;
      min-height: 362px;
      display: flex;
      justify-content: center;
    }
    .hero{
      display: flex;
      justify-content: center;
    }
    .hero--ca{
      background-image: url('../../public/img/map-canada-small3.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .hero--us{
      background-image: url('../../public/img/map-us-small3.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .hero > a{
      width: 288px;
      color: #0E1F22;
      border-width: 1px;
      border-color: #FFD600;
      border-style: solid;
      border-radius: 0px;
      font-size: 16px;
      font-weight: 600;
      padding: 0.75em 1.5em;
      background-color: #FFD600;
      text-decoration: none;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 1rem;
    }
    .hero >a.disabled{
      background-color: #E1E3E4;
      border-color: #E1E3E4;
      color: #969da0;
      cursor: default;
    }
    .hero > a:hover{
      background-color: #ffffff;
      border-color: #000000;
    }
    .hero > a:hover{
      background-color: #E1E3E4;
      border-color: #E1E3E4;
    }
    .text-title{
      font-weight: bold;
    }
    .body-text{
      max-width: 78%;
      text-align: center;
    }

    @keyframes dropIn {
      0% {
        transform: translateY(-15%);
      }
      100%{
        transform: translateY(0);
      }
    }
    @keyframes phaseIn {
      0% {
        opacity: 60%;
        width: 100px;
      }
      100%{
        opacity: 100%;
        width: 160px;
      }
    }

    .iframe-container iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      background: transparent;
    }
  }
</style>

<script>


export default {
  name: 'compliance',
  data: function() {
    return {
      reports: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        },
      ],
      errors: [],
      dashboardUrl: {},
      complianceType: this.$root.user.complianceType,
      //state 0 is home, 1 is USA, 2 is Canada
      pageState: 0,
  }
  },
  created() {
  }
}
</script>
